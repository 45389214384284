import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import AddQualification from "./AddQualification";
import axios from 'axios';
import Button from 'react-bootstrap/Button';

const Portfolio = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
	const [qualifications, setQualifications] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [update, setUpdate] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);


    const handleCloseRemoveConfirmation = () => setShowRemoveConfirmation(false);
  	const handleShowRemoveConfirmation = (qualification) => {
        setQualification(qualification);
        setShowRemoveConfirmation(true);
    };

    const userInfo = useRef();

	useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

	const fetchData = async () => {
        try
        {
            let userDataResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
            userInfo.current = userDataResponse.data;
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/user/" + userInfo.current.userId, 
                { withCredentials: true, params: { includeRequirements: false } });
            setQualifications(qualificationResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemove = async (e) => {
        e.preventDefault();
        try 
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/holder/delete/" + qualification.uuid, 
                { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            setUpdate(true);  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            handleCloseRemoveConfirmation();
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Portfolio</title>
			</Helmet>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Portfolio</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <AddQualification 
                        setMessage={setMessage}
                        setAlertVariant={setAlertVariant} 
                        setUpdate={setUpdate} />
                    <h5 className="Margin-Bottom">Valid Qualifications</h5>
                    <div className="Margin-Bottom">
                        { qualifications.map((qualification) => {
                            if (qualification.valid)
                            {
                                return(
                                    <div className="list-group" key={qualification.uuid + "-valid"}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{qualification.qualificationName}</h5>
                                                <small>
                                                    {qualification.versionName}
                                                </small> 
                                            </div>
                                            { qualification.description &&
                                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                                    <p className="mb-1">{qualification.description}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            else
                            {
                                return null
                            }
                        })}
                    </div>
                   
                    { !qualifications.some(e => Boolean(e.valid) === true) &&
                        <p className="Center-Text">Your portfolio contains no valid qualifications.</p>
                    }  
                    <h5 className="Margin-Bottom">Invalid Qualifications</h5>
                    <div className="Margin-Bottom">
                        { qualifications.map((qualification) => {
                            if (!qualification.valid)
                            {
                                return(
                                    <div className="list-group" key={qualification.uuid + "-valid"}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{qualification.qualificationName}</h5>
                                                <small>
                                                    {qualification.versionName}
                                                </small> 
                                            </div>
                                            { qualification.description &&
                                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                                    <p className="mb-1">{qualification.description}</p>
                                                </div>
                                            }
                                            <div>
                                                <Button variant="danger" onClick={(e) => handleShowRemoveConfirmation(qualification)} type="submit">
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else
                            {
                                return null
                            }
                        })}
                    </div>
                    { !qualifications.some(e => Boolean(e.valid) === false) &&
                        <p className="Center-Text">Your portfolio contains no invalid qualifications.</p>
                    }  
                </div>
                <Modal show={showRemoveConfirmation} onHide={handleCloseRemoveConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Qualification Removal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you certain you want to remove version {qualification.versionName} of {qualification.qualificationName} from your portfolio?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRemoveConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleRemove}>
                        Remove Qualification
                    </Button>
                    </Modal.Footer>
                </Modal> 
            </div>     
		</HelmetProvider>
	);
}

export default Portfolio;