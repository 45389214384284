import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import RequirementTypeSelect from "./RequirementTypesSelect";
import Modal from 'react-bootstrap/Modal';
import QualificationSelect from "../../QualificationSearchBar" 

const EditRequirement = () => {

    const [title, setTitle] = useState("Create Requirement");
    const [updateButtonText, setUpdateButtonText] = useState("Create Requirement");
    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
    const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
    const [displayDeleteButton, setDisplayDeleteButton] = useState(false);
    const [requirementUuid, setRequirementUuid] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [requirementTypeId, setRequirementTypeId] = useState();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [displayForm, setDisplayForm] = useState(true);
    const [organization, setOrganization] = useState({});
    const [qualificationUuid, setQualificationUuid] = useState("00000000-0000-0000-0000-000000000000");
    const [organizationInput, setOrganizationInput] = useState("");
    const [qualifications, setQualifications] = useState([]);
    const [expirationDays, setExpirationDays] = useState(0);

    const organizationUuid = useRef();
    const createdNewRequirement = useRef(false);
    const deleted = useRef(false);
    

    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
  	const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

    useEffect(() => {
        let fetchData = async () => {
            const queryParameters = new URLSearchParams(window.location.search);
            organizationUuid.current = queryParameters.get("organizationUuid");
            let organizationResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/organization/' + organizationUuid.current, 
                { withCredentials: true } );
            setOrganization(organizationResult.data);

            if (!createdNewRequirement.current)
            {
                setRequirementUuid(queryParameters.get("requirementUuid"));
            }
            if (requirementUuid && !deleted.current)
            {
                
                try
                {
                    let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/qualification/requirement/' + requirementUuid, 
                        { withCredentials: true } );
                    setTitle("Edit Requirement");
                    setUpdateButtonText("Update Requirement");
                    setName(result.data.name);
                    setDescription(result.data.description);
                    setRequirementTypeId(result.data.requirementType.id);
                    setExpirationDays(result.data.expirationDays);
                    setDisplayDeleteButton(true);
                    if (Number(result.data.requirementType.id) === 2)
                    {
                        setQualificationUuid(result.data.qualification.uuid);
                        let qualificationOrgResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/organization/' + result.data.qualification.organizationUuid, 
                            { withCredentials: true } );
                        setOrganizationInput(qualificationOrgResult.data.name);
                        let qualificationResults = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/organization/" + result.data.qualification.organizationUuid, 
                            { 
                                withCredentials: true 
                            });
                        setQualifications([{name: 'Select Qualification', uuid: ''}, ...qualificationResults.data]);
                    }
                }
                catch (error)
                {
                    console.log(error)
                    if (error.response.status === 404)
                    {
                        return
                    }
                    setAlertVariant("danger");
                    if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                        setMessage(error.response.data.message)
                    } else {
                        setMessage("Unknown Error");
                    }
                }
            }
        };
		fetchData();
	}, [requirementUuid, displayForm]);

    let handleUpdate = async (e) => {
        e.preventDefault();

        setUpdateButtonClicked(true);

        const requirement = {
            name: name,
            description: description,
            requirementTypeId: requirementTypeId,
            organizationUuid: organizationUuid.current,
            qualificationUuid: qualificationUuid,
            expirationDays: expirationDays
        };

        try 
        {
            if (requirementUuid)
            {
                let response = await axios.put(process.env.REACT_APP_BASE_API_URL + '/qualification/requirement/update/' + requirementUuid, 
                    requirement, { withCredentials: true } );
                setMessage(response.data.message);
            }
            else
            {
                let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/qualification/requirement/create', requirement, { withCredentials: true } );
                setRequirementUuid(response.data.uuid);
                createdNewRequirement.current = true;
                setMessage(response.data.message);
                setTitle("Edit Requirement");
                setUpdateButtonText("Update Requirement");
            }
            setAlertVariant("primary");  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setUpdateButtonClicked(false);
        }
    };

    let handleDelete = async (e) => {
        e.preventDefault();

        setDeleteButtonClicked(true);

        try 
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + '/qualification/requirement/delete/' + requirementUuid, 
                { withCredentials: true } );
            setName("");
            setDescription("");
            setDisplayDeleteButton(false);
            setShowDeleteConfirmation(false);
            setRequirementUuid("");
            setDisplayForm(false);
            //setTitle("Create Requirement");
            //setUpdateButtonText("CreateRequirement");
            deleted.current = true;
            setMessage(response.data.message);
            setAlertVariant("primary");  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setDeleteButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Requirement</title>
            </Helmet>
            <div className="p-2">
                { requirementUuid &&
                    <h6><a href={"/organization?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '} 
                    <a href={"/requirement/list?organizationUuid=" + organization.uuid }>Requirements</a>{' > '} 
                    Edit</h6>
                }
                { !requirementUuid &&
                    <h6><a href={"/organization?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '}
                    <a href={"/requirement/list?organizationUuid=" + organization.uuid }>Requirements</a>{' > '}
                    Create</h6>
                }
            </div>
            <div className="d-flex align-items-center justify-content-center p-3">
				<div className="Outer">
					<table>
						<tbody>
							<tr>
								<td className="d-flex align-items-center justify-content-center">
									<h3>{title}</h3>
								</td>
							</tr>
							<tr>
								<td className="d-flex align-items-center justify-content-center Outer">    
									{message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
								</td>
							</tr>
                            { displayForm &&
                                <tr>
                                    <td className='d-flex align-items-center justify-content-center'>
                                        <Form onSubmit={handleUpdate} className='Form Margin-Bottom'>
                                            <RequirementTypeSelect 
                                                requirementTypeId={requirementTypeId}
                                                setRequirementTypeId={setRequirementTypeId} />
                                            { Number(requirementTypeId) === 2 &&
                                                <QualificationSelect 
                                                    setQualificationUuid={setQualificationUuid}
                                                    organizationInput={organizationInput}
                                                    setOrganizationInput={setOrganizationInput}
                                                    qualifications={qualifications}
                                                    setQualifications={setQualifications}
                                                    qualificationUuid={qualificationUuid}
                                                />
                                            }
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Name *</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Requirement Name"
                                                    value={name}
                                                    name="name" 
                                                    onChange={(e) => setName(e.target.value)} 
                                                    required />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formDescription">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" placeholder="Enter Description"
                                                    rows={3}
                                                    value={description}
                                                    name="description" 
                                                    onChange={(e) => setDescription(e.target.value)} />
                                            </Form.Group>
                                            
                                            <Form.Group className="mb-3" controlId="formDays">
                                                <Form.Label>Expiration - Days After Completion (0 for None) *</Form.Label>
                                                <Form.Control type="number" placeholder="Enter Days Until Expiration"
                                                    min={0}
                                                    value={expirationDays}
                                                    name="days" 
                                                    onChange={(e) => setExpirationDays(e.target.value)} 
                                                    required />
                                            </Form.Group>
                                            <p>
                                                { '* Required' }
                                            </p>
                                            <Button variant="primary" type="submit" hidden={updateButtonClicked}>
                                                {updateButtonText}
                                            </Button>
                                            <Button variant="primary" disabled hidden={!updateButtonClicked}>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        </Form>
                                    </td>
                                </tr>
                            }
                            { displayDeleteButton && displayForm &&
								<tr>
									<td>
										<div className="Form">
											Caution, this cannot be undone!<br />
											<Button variant="danger" onClick={handleShowDeleteConfirmation} type="submit" hidden={deleteButtonClicked}>
												Delete Requirement
											</Button>
											<Button variant="danger" disabled hidden={!deleteButtonClicked}>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												Deleting...
											</Button>
											<Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}>
												<Modal.Header closeButton>
													<Modal.Title>Confirm Requirement Deletion</Modal.Title>
												</Modal.Header>
												<Modal.Body>Are you certain you want to delete the requirement ({name})? This cannot be undone!</Modal.Body>
												<Modal.Footer>
												<Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
													Cancel
												</Button>
												<Button variant="danger" onClick={handleDelete}>
													Delete Requirement
												</Button>
												</Modal.Footer>
											</Modal>
										</div>
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
        </HelmetProvider>
	);
};

export default EditRequirement;
