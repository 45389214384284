import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button'

import EvaluatorSearchBar from "./EvaluatorSearchBar";

const Evaluator = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState({});
    const [qualification, setQualification] = useState({});
    const [evaluators, setEvaluators] = useState([]);
    const [update, setUpdate] = useState(false);

    const qualificationUuid = useRef("");

    function formatDate(timestampString) 
    {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(timestampString).toLocaleDateString(undefined, options)
    }

    useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    qualificationUuid.current = queryParameters.get("qualificationUuid");
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualificationUuid.current, 
                { withCredentials: true, params: { includeRequirements: false } });
            setQualification(qualificationResponse.data);
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + qualificationResponse.data.organizationUuid, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
            let evaluatorsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluators/" + qualificationUuid.current, 
                { withCredentials: true });
            setEvaluators(evaluatorsResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveEvaluator = async (e, evaluatorUuid) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluator/delete/" + evaluatorUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Qualification Evaluators</title>
            </Helmet>
            <div className="p-2">
                <h6><a href={"/organization?organizationUuid=" + organization.uuid}>{organization.name}</a>
                {' > '}<a href={"/qualification/list?organizationUuid=" + organization.uuid}>Qualifications</a>
                {' > '}<a href={"/qualification/edit?organizationUuid=" + organization.uuid + "&qualificationUuid=" + qualification.uuid}>{qualification.name}</a>
                {' > '}Evaluators</h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Qualification Evaluators</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    
                    <div className="Details Margin-Bottom">
                        <div className="Center-Text">
                            <h5>{qualification.name}</h5>
                        </div>
                        { qualification.description &&
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {qualification.description}
                            </div>
                        }
                    </div>
                    <EvaluatorSearchBar
                        organizationUuid={organization.uuid}
                        qualificationUuid={qualificationUuid.current}
                        setAlertVariant={setAlertVariant}
                        setMessage={setMessage}
                        setUpdate={setUpdate}
                    />
                    <h5 className="Margin-Bottom">Current Evaluators</h5>
                    { evaluators.map((evaluator) => {
                            return(
                                <div className="list-group" key={evaluator.email}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{evaluator.surname}, {evaluator.givenName}</h5>
                                            <small>
                                                Evaluator Since: {formatDate(evaluator.created)} 
                                            </small> 
                                        </div>
                                        <div>
                                            Email: {evaluator.email}
                                        </div>
                                        <div>
                                            Telephone: {evaluator.telephoneNumber}
                                        </div>
                                        <div>
                                            Organization: {evaluator.organization}
                                        </div>
                                        <div>
                                            {evaluator.addressLine1}
                                        </div>
                                        { evaluator.addressLine2 &&
                                            <div>
                                                {evaluator.addressLine2}
                                            </div>
                                        }
                                        <div>
                                            {evaluator.city}, {evaluator.stateProvince} {evaluator.postalCode}
                                        </div>
                                        <div>
                                            {evaluator.country}
                                        </div>
                                        <Button variant="danger" onClick={(e) => handleRemoveEvaluator(e, evaluator.uuid, false)} type="submit">
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                            )
                    })}
                    { evaluators.length < 1 &&
                        <p className="Center-Text">This qualification has no evaluators.</p>
                    } 
                </div> 
            </div> 
        </HelmetProvider>
	);
}

export default Evaluator;