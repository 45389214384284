import React, { useState } from "react";
import axios from 'axios';
import { FaSearch } from "react-icons/fa";
import Button from 'react-bootstrap/Button'

import "./Evaluators.css";
import EvaluatorList from "./EvaluatorList";

const EvaluatorSearchBar = ({organizationUuid, qualificationUuid, setAlertVariant, setMessage, setUpdate}) => {

    const [evaluatorSearchResults, setEvaluatorSearchResults] = useState([]);
    const [evaluatorInput, setEvaluatorInput] = useState("");
    const [evaluator, setEvaluator] = useState();

    const formatDate = (dateInputString) => {
        const date = Date.parse(dateInputString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
        return Intl.DateTimeFormat(undefined, options).format(date);
	}

    let handleCreateEvaluator = async (e) => {
        e.preventDefault();
        try
        {
            let newEvaluator = {
                email: evaluator.user.email
            };
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluator/create/" + qualificationUuid, 
                newEvaluator, { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setEvaluator();
            setEvaluatorInput("");
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

    const searchEvaluatorData = async (value) => {
        try
        {
            if (!value)
            {
                return
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliations/active/" + organizationUuid, 
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });

            setEvaluatorSearchResults(results.data)
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleEvaluatorChange = (value) => {
        setEvaluatorInput(value);
        if (value === "")
        {
            setEvaluatorSearchResults([]);
        }
        else
        {
            searchEvaluatorData(value);
        }
    }

    return (
        <>
            <div className="input-wrapper">
                <FaSearch id="search-icon" />
                <input placeholder="Type to search affiliated users..."
                className="input"
                value={evaluatorInput} 
                onChange={(e) => handleEvaluatorChange(e.target.value)} />
            </div>
            <div className="Margin-Bottom">
                <EvaluatorList 
                evaluatorSearchResults={evaluatorSearchResults} 
                setEvaluatorInput={setEvaluatorInput} 
                setEvaluatorSearchResults={setEvaluatorSearchResults}
                setEvaluator={setEvaluator}
                />
            </div>
            { evaluator &&
                <div className="Details Margin-Bottom">
                    <h5 className="mb-1">{evaluator.user.surname}, {evaluator.user.givenName}</h5>
                    <div>
                        Email: {evaluator.user.email}
                    </div>
                    <div>
                        Telephone: {evaluator.user.telephoneNumber}
                    </div>
                    <div>
                        Organization: {evaluator.user.organization}
                    </div>
                    <div>
                        {evaluator.user.addressLine1}
                    </div>
                    { evaluator.user.addressLine2 &&
                        <div>
                            {evaluator.user.addressLine2}
                        </div>
                    }
                    <div>
                        {evaluator.user.city}, {evaluator.user.stateProvince} {evaluator.user.postalCode}
                    </div>
                    <div>
                        {evaluator.user.country}
                    </div>
                    <div className="Margin-Bottom">
                        Affiliated: {formatDate(evaluator.confirmed)}
                    </div>
                    <Button variant="primary" onClick={(e) => handleCreateEvaluator(e)} type="submit">
                        Add Evaluator
                    </Button>
                </div>
            }
        </>
    );
};

export default EvaluatorSearchBar;
