import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";

import SearchBar from '../../SearchBar/SearchBar';
import SearchResultsList from '../../SearchBar/SearchResultsList';

const Requirement = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [organizationName, setOrganizationName] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const organizationUuid = useRef();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    organizationUuid.current = queryParameters.get("organizationUuid");
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, 
                { withCredentials: true });
            let requirementsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/organization/requirement/" + organizationUuid.current, 
                { withCredentials: true });
            setOrganizationName(organizationResponse.data.name);
            setRequirements(requirementsResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Requirements</title>
            </Helmet>
            <div className="p-2">
                <h6><a href={"/organization?organizationUuid=" + organizationUuid.current }>{organizationName}</a> {'>'} Requirements</h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text Margin-Bottom">Requirements</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Margin-Bottom">
                        <SearchBar 
                            setSearchResults={setSearchResults}
                            apiEndpoint={ "/qualification/organization/requirement/" + organizationUuid.current }
                            searchBoxPlaceholder="Type to search the organization's requirements..." />
                        <SearchResultsList results={searchResults} 
                            urlPrefix={ "/requirement/edit?organizationUuid=" + organizationUuid.current + "&requirementUuid=" } />
                    </div>
                    <div className="Margin-Bottom">
                        <Button as="a" href={ "/requirement/edit?organizationUuid=" + organizationUuid.current }>Create Requirement</Button>
                    </div>
                    <h5 className="Margin-Bottom">Current Requirements</h5>
                    { requirements.map((requirement) => {
                            return(
                                <div className="list-group" key={requirement.uuid}>
                                    <a href={ "/requirement/edit?organizationUuid=" + organizationUuid.current + "&requirementUuid=" + requirement.requirementUuid } className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{requirement.name}</h5>
                                            <small>{requirement.requirementType.name}</small>
                                            </div>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            <p className="mb-1">{requirement.description}</p>
                                        </div>
                                    </a>
                                </div>
                            )
                    })}
                    { requirements.length < 1 &&
                        <p className="Center-Text">This organization has no requirements.</p>
                    } 
                </div> 
            </div> 
        </HelmetProvider>
	);
};

export default Requirement;
