import React from "react";

import "./Evaluators.css";

const EvaluatorList = ({setEvaluatorSearchResults, evaluatorSearchResults, setEvaluatorInput, setEvaluator}) => {

    const handleEvaluatorSelect = async (result) => {
        setEvaluatorInput(result.user.surname + ", " + result.user.givenName + " (" + result.user.email + ")");
        setEvaluatorSearchResults([]);
        setEvaluator(result);
    }

    if (evaluatorSearchResults.length > 0)
    {
        return (
            <div className="results-list">
                {evaluatorSearchResults.map((result) => {
                    return <div className="search-result" key={result.user.email} onClick={() => handleEvaluatorSelect(result)}>
                                {result.user.surname}, {result.user.givenName} ({result.user.email})
                            </div>
                })}
            </div>
        );
    }
};

export default EvaluatorList;
